/* eslint-disable */
import React from 'react'
import Layout from '../../components/theme/Layout'
import Header from '../../components/theme/Header'
import DidYouKnow from '../../components/common/DidYouKnow'
import Offers from '../../components/common/Offers'
import Footer from '../../components/theme/Footer'
import RenderBlock from '../../components/blocks/RenderBlock'
import styled from '@emotion/styled'
import {StaticQuery, graphql} from 'gatsby'
import Vimeo from '@u-wave/react-vimeo'
import Slider from "react-slick";
import "../../contest/css/slick.css";
import "../../contest/css/slick-theme.css";

import BannerImg from '../../assets/pets-images/banner@2x.png'
import LogoImg from '../../assets/pets-images/logo_pets@2x.png'

import Cleo from '../../assets/pets-images/cleo1@2x.png'
import Beng from '../../assets/pets-images/beng1@2x.png'
import Chaitea from '../../assets/pets-images/chai1@2x.jpg'
import Percy from '../../assets/pets-images/percy1@2x.jpg'
import Dorris from '../../assets/pets-images/doris.png'
import William from '../../assets/pets-images/william1@2x.png'
import Partner1 from '../../assets/pets-images/partner1@2x.png'
import Partner2 from '../../assets/pets-images/partner2@2x.png'
import Partner3 from '../../assets/pets-images/partner5.png'
import Partner4 from '../../assets/pets-images/partner4@2x.png'
import Partner5 from '../../assets/pets-images/logo_1_resized.png'
import Partner6 from '../../assets/pets-images/logo_2_resized.png'
import Partner7 from '../../assets/pets-images/logo_3_resized.png'
import Partner8 from '../../assets/pets-images/logo_4_resized.png'
import Partner9 from '../../assets/pets-images/logo_5_resized.png'
import Partner10 from '../../assets/pets-images/logo_6_resized.png'
import Partner11 from '../../assets/pets-images/logo_7_resized.png'
import Partner12 from '../../assets/pets-images/logo_8_resized.png'
import Partner13 from '../../assets/pets-images/logo_9_resized.png'
import Partner14 from '../../assets/pets-images/logo_10_resized.png'

import Fb from '../../assets/pets-images/fb@2x.png'
import Insta from '../../assets/pets-images/insta@2x.png'

import Contrib1 from '../../assets/images/contrib1.jpg'
import Contrib2 from '../../assets/images/contrib2.jpg'
import Contrib3 from '../../assets/images/contrib3.jpg'

import VideoBlock from '../../components/blocks/VideoBlock'
import {ImageLeftTextRightBlock} from '../../components/blocks/ImageLeftTextRightBlock'
import {Box} from "../../components/common/Grid";
import {jsx} from "@emotion/core";
import sendEvent from "../../helpers/sendAnalytics";
import EventTracker from "../../components/common/EventTracker";

import { detect } from 'detect-browser'

const browser = detect();

import PetsSignUpBlock from '../../components/blocks/PetsSignUpBlock'

var petsArray = [
  {
    name: 'Percy',
    text: 'Percy’s “sisters” were looking for a kitten. But when the older, one-eyed black cat snuggled up, they knew it was time to make him part of the family instead.  ',
    image: Percy,
    video_url: 'https://vimeo.com/409827606',
    title: 'The one-eyed cat who’s as cute as a kitten '
  },
  {
    name: 'Ben-G',
    text: 'Ben-G was a foster dog when his temporary parents decided they just couldn’t let him go. So now he shares his loving family with fosters who come to visit.  ',
    image: Beng,
    video_url: 'https://vimeo.com/409825286',
    title: 'The foster dog they couldn’t let go of '
  },
  {
    name: 'Doris',
    text: 'Doris ruled the roost when she first came home, but when her parents brought home another cat and a dog she learned that more family just means more love! ',
    image: Dorris,
    video_url: 'https://vimeo.com/409826929',
    title: 'The cat that learned that the more pets, the merrier '
  },
  {
    name: 'Cleo',
    text: 'Cleo leads her whole family on their daily walks; they all go with her. Not only is it an impressive gathering but it brings the family together!  ',
    image: Cleo,
    video_url: 'https://vimeo.com/409826378',
    title: 'The little dog with the big entourage '
  },
  {
    name: 'Chai Tea',
    text: 'The story of Chai Tea goes together with the story of Boba, two cats adopted together who spend their days spreading love and glamourizing their owners’ photo reel!  ',
    image: Chaitea,
    video_url: 'https://vimeo.com/409825744',
    title: 'The city cat turned social media star '
  },
  {
    name: 'William',
    text: 'William was a street rescue when his pet parents fell in love with him at the animal shelter. Now healthy and happy, he brought them together as a family.   ',
    image: William,
    video_url: 'https://vimeo.com/409827953',
    title: 'The dog that turned a couple into a family '
  },
]

const HeroWrapper = styled.div`
    width: 100%;
    height: auto;
    background-image: url(${BannerImg});
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 30vh;
    @media(max-width:1150px){
        padding-top: 10vh;
    }
    @media(max-width:960px){
        padding-top: 0vh;
    }
`

const VideoBlockWrapper = styled.div`
  & > div > iframe {
    width: 100%;
  }
`

const TextContainer = styled.div`
    width: 100%;
    display: flex;
    align-items:center;
    margin-bottom: 5%;
    @media(max-width:960px){
        flex-direction: column;
    }
`

const LogoContainer = styled.img`
    width: 30%;
    @media(max-width:960px){
        width: 50%;
        margin: 100px 25% 0;
    }
`
const TextDetail = styled.div`
    width: 80%;
    max-width: 1000px;
    padding: 0% 5% 0 10px;
    color: white;
    font-family: 'Montserrat',sans-serif;
    line-height: 26pt;
    > h2 {
        font-family: 'Montserrat',sans-serif;
        font-weight: 600;
    }
    @media(max-width:960px){
        width: 100%;
        color: black;
        h2 {
            color: #2e3192;
            font-weight: bold;
        }
    }
`
const MainWrapper = styled.div`
    display: block;
    width: 90%;
    margin: 0 5%;
    @media(max-width:960px){
        width: 96%;
        margin: 0 2%;
    }
`
const PadWrapper = styled.div`
    display: block;
    width: 90%;
    margin: 70px 5%;
    text-align: center;
    font-family: 'Montserrat',sans-serif;
    @media(max-width:960px){
        width: 96%;
        margin: 50px 2% 0;
    }
`
const VideosContainer = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
    @media(max-width:960px){
        flex-direction: column;
    }
`
const LargeVideo = styled.div`
    width: 85.5%;
    position: relative;
    @media(max-width:960px){
        width: 100%;
    }
`

const VideoDetails = styled.div`
    position: absolute;
    bottom: 15%;
    left: 20px;
    width: 60%;
    font-family: 'Montserrat',sans-serif;
    color: white;
    line-height: 20pt;
    padding: 15px;
    z-index: 10;
    border-radius: 10px;
    > h6 {
        margin: 0;
        font-size: 18pt;
    }
    > h3 {
        margin: 0;
        font-size: 22pt;
    }
    > span {
        width: 40px;
        transition: 0.2s;
        height:3px;
        display:block;
        background:#ABE0FA;
        border-radius: 5px;
        margin: 10px 0 25px;

    }
    > div {
        text-transform: uppercase;
        font-weight: bold;
        display: flex;
        align-items: center;
        img {
            display: inline-block;
            margin: 0 0 0 10px;
        }
    }
    @media(max-width:1150px){
      bottom: 15%;
      left: 40px;
    }
    @media(max-width:850px){
        position:relative;
        width: 100%;
        left: auto;
        bottom: auto;
    }
`

const BlackOverlay = styled.div`
    background: black;
    opacity: 0.4;
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    z-index:-1;
    border-radius: 10px;
    height: 100%;
    @media(max-width:1150px){
      opacity: 0.6;
      background-color: transparent;
      background-image:linear-gradient(black,transparent);
    }
    @media(max-width:960px){
        background: #0b2867;
        opacity: 0.6;
        margin-bottom: 15px;
    }
`


const SmallVideos = styled.div`
    width: 14.5%;
    display: flex;
    flex-direction: column;
    align-content:space-around;
    justify-content:space-around;
    @media(max-width:960px){
        width: 100%;
        flex-direction: row;
    }
    @media(max-width:800px){
        width: 100%;
        display: block;
    }
`
const PetVideo = styled.div`
    cursor:pointer;
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin: 0 0 5px 10px;
    transition: 0.2s;
    background-image: url(${props => props.backgroundImage});
    height: 18.5%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    :hover {
        margin: 0 0 5px 20px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        span {
            width: 40px;
        }
    }
    p {
        font-size: 15pt;
        color: white;
        font-family: 'Montserrat',sans-serif;
        text-transform: uppercase;
        margin: 0;
        padding: 0 0 5px 0;
    }
    @media(max-width:960px){
        width: 100%;
        height: 150px;
        margin: 5px;
        padding: 0;
        border-radius: 5px;
        :hover {
            margin: 5px 0;
        }
    }
    @media(max-width:800px){
        width: 100%;
        height: 150px;
        margin: 5px 0;
        padding: 0;
        border-radius: 5px;
        :hover {
            margin: 5px 0;
        }
    }
`

const ThumbText = styled.div`
    text-align: right;
    margin-top: 10px;
    margin-right: 10%;
    width: 90%;
    > span {
      width: 0px;
      transition: 0.2s;
      height:3px;
      display:block;
      background:#ABE0FA;
      margin-left: auto;
      border-radius: 5px;
    }
    @media(max-width:960px){
        margin-top: 50px;
    }
`

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 10%;
    opacity: 1;
    align-items: center;
    justify-content: space-around;
    a img {
      max-width: 160px;
      width: auto !important;
      height: auto !important;
      margin: 0 auto 50px;
      display: block;
      opacity: 0.6;
    }
    @media(max-width:960px){
      width: 100%;
      margin: 0 0 50px;
      text-align: center;
        a {
          display: block;
          width: 50% !important;
        }
        img {
          width: 100%;
          margin: 40px auto;
          display: block;
        }
    }
`
const WhiteHeader = styled.h2`
  color: white !important;
  position: absolute;
  font-family: 'Montserrat',sans-serif;
  margin-left: 30px;
  font-weight: 500;
  font-size: 25pt;
  top: 0px;
  text-shadow: 1px 1px 3px black;
  @media(max-width:1150px){
      top: 40px;
      font-size: 20pt;
      margin-left: 40px;
  }
  @media(max-width:850px){
      top: 0px;
      font-size: 20pt;
      margin-left: 10px;
  }
`

const ShelterText = styled.p`
  font-family: 'Montserrat',sans-serif;
  line-height: 20pt;
  display: block;
  margin: 0 auto;
  max-width: 700px;
`

const Contribution = styled.img`
  flex: 1;
  opacity: 1 !important;
  @media(max-width:960px){
    width: 100% !important;
    max-width: 100% !important;
    margin: 5px 0 !important;
  }
`

const Spacer = styled.div`
    display: block;
    width: 100%;
    height: 75px;
`

const SliderFlex = styled(Slider)`
  .slick-slide {
    height: 190px;
    > div {
      height: 190px;
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: center;
    }
  }
  img {
    width: 160px;
    height: auto;
    margin: auto;
  }
  .smaller-pic {
    width: 100px;
    height: auto;
  }
`


class PetsPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      videoSize: false,
      currentVideo: petsArray[5],
      showDescription: true,
      isIE:false
    }

    this.quarterPassed = [];
    this.halfPassed = [];
    this.threeQuarterPassed = [];
    this.watched = [];

    this.onTimeUpdate.bind(this);
  }

  componentDidMount() {
    let newSize = 595
    if (window.innerWidth > 1366) {
      newSize = window.innerWidth * 42.8 / 100
    }
    if (window.innerWidth < 960 && window.innerWidth > 800) {
      newSize = 450
    }
    if (window.innerWidth < 800) {
      newSize = 250
    }
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get('pet');
    if (param === 'cats') {
      this.setState({currentVideo: petsArray[0]})
      this.orderByCats()
    } else if (param === 'dogs') {
      this.setState({currentVideo: petsArray[1]})
      this.orderByDogs()
    } else {
      if(param){
        let newArray = []
        for (var i = 0; i < petsArray.length; i++) {
          if(param == petsArray[i].name.toLowerCase().replace(/\s/g, '')){
            this.setState({currentVideo:petsArray[i]})
          } else {
            newArray.push(petsArray[i])
          }
        }
        petsArray = newArray
      }
    }

    this.setState({videoSize: newSize})
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    this.setState({isIE:isIE11})
  }

  orderByDogs() {
    let newArray = []
    newArray[5] = petsArray[1]
    newArray[0] = petsArray[5]
    newArray[1] = petsArray[3]
    newArray[2] = petsArray[0]
    newArray[3] = petsArray[2]
    newArray[4] = petsArray[4]
    petsArray = newArray
  }

  orderByCats() {
    let newArray = []
    newArray[5] = petsArray[0]
    newArray[0] = petsArray[2]
    newArray[1] = petsArray[4]
    newArray[2] = petsArray[1]
    newArray[3] = petsArray[5]
    newArray[4] = petsArray[3]
    petsArray = newArray
  }

  getVideoId(url) {
    const matches = url.match(/vimeo.com\/(\d+)/);
    return matches[1];
  }

  onTimeUpdate(event, provider, name, videoId) {
    if (provider === 'vimeo') {
      if (event.percent > 0.25 && event.percent < .5 && !this.quarterPassed.includes(videoId)) {
        sendEvent({
          ga: {
            category: 'Engagement',
            label: 'Video_Progress',
            action: `Video_25%_${name}`
          }
        });

        this.quarterPassed.push(videoId);
      }

      if (event.percent > 0.5 && event.percent < .75 && !this.halfPassed.includes(videoId)) {
        sendEvent({
          ga: {
            category: 'Engagement',
            label: 'Video_Progress',
            action: `Video_50%_${name}`
          }
        });

        this.halfPassed.push(videoId);
      }

      if (event.percent > 0.75 && event.percent < 1 && !this.threeQuarterPassed.includes(videoId)) {
        sendEvent({
          ga: {
            category: 'Engagement',
            label: 'Video_Progress',
            action: `Video_75%_${name}`
          }
        });

        this.threeQuarterPassed.push(videoId);
      }

      if (event.percent === 1 && !this.watched.includes(videoId)) {
        sendEvent({
          ga: {
            category: 'Engagement',
            label: 'Video_Progress',
            action: `Video_100%_${name}`
          }
        });

        this.watched.push(videoId);
      }
    }
  }


  render() {

    var blocks = [this.props.data.startPage.blocks[2]]
    var rhs = this.props.data.prismic

    blocks[0].right[0].text = '<p class="p1"><span class="s1">Check out our easy-to-follow tips and tricks to see how ROYALE<sup>®</sup> products can help you and your pets</span></p>'
    blocks[0].right[1].link = '/home-solutions?category=W-OM_xIAACkAAzcq'
    var settings = {
      dots: true,
      autoplay:true,
      autoplaySpeed: 5000,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: false,
      pauseOnDotsHover: true,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 919,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots:false
          }
        }
      ]
    }


    const pets = petsArray.map((pet, index) => {
      return (
        index < 5 ?
          <PetVideo
            key={index}
            backgroundImage={pet.image}
            onClick={() => {
              let petHolder = this.state.currentVideo
              petsArray[index] = petHolder
              this.setState({currentVideo: pet})
              window.scrollTo({
                top: (window.pageYOffset + document.getElementById('videos').getBoundingClientRect().top) - 10,
                left: 0,
                behavior: 'smooth'
              })
            }}
          >
            <ThumbText>
              <p>{pet.name}</p>
              <span></span>
            </ThumbText>
          </PetVideo>
          : null
      )
    })
    return (
      <Layout
        meta={{
          title: 'Home for Every Pet Project | ROYALE®'
        }}
        urls={{
          'en': `/pets`,
          'fr': `/fr/ami`
        }}
        locale='en'
      >
        <Header
          urls={{
            'en': `/pets`,
            'fr': `/fr/ami`
          }}
          locale='en'
        />
        <HeroWrapper>
          <MainWrapper>
            <TextContainer>
              <LogoContainer src={LogoImg}/>
              <TextDetail>
                <h2>A pet can bring comfort, companionship and joy into Canadian homes, but the bond when a shelter
                  animal finds their forever home is unique. </h2>
                <p><b>Many families can’t imagine home without their four-legged companions, especially during times of
                  uncertainty and stress.</b> And, for the over 100,000 cats and dogs taken in by Canadian shelters
                  every year finding a forever home creates an everlasting joy. As The Kitten Brand<sup>®</sup>,
                  ROYALE<sup>®</sup> believes that everyone deserves to experience that joy. It’s why we started the <b>Home
                    for Every Pet Project</b>, to help increase awareness and raise funds for the in-shelter care
                  crucial to matching pets with loving families. Check out the successful adoption stories below.</p>
              </TextDetail>
            </TextContainer>
          </MainWrapper>
          <MainWrapper>
            <VideosContainer id="videos">
              <LargeVideo>
                <WhiteHeader>Adopting Joy</WhiteHeader>
                {this.state.videoSize ?
                  <VideoBlockWrapper>
                    <Vimeo
                      video={this.getVideoId(this.state.currentVideo.video_url)}
                      height={this.state.videoSize}
                      onPlay={() => {
                        this.setState({showDescription: false})
                      }}
                      onPause={() => {
                        this.setState({showDescription: true})
                      }}
                      onTimeUpdate={(e) => this.onTimeUpdate(e, 'vimeo', this.state.currentVideo.name, this.getVideoId(this.state.currentVideo.video_url))}
                      key={this.state.currentVideo.video_url}
                    />
                  </VideoBlockWrapper>
                  :
                  null
                }
                {this.state.showDescription ?
                  <VideoDetails>
                    <h6>{this.state.currentVideo.name}</h6>
                    <span></span>
                    <h3>{this.state.currentVideo.title}</h3>
                    <p>{this.state.currentVideo.text}</p>
                    <div>
                      Share to :
                      <a
                        href={"https://www.facebook.com/sharer/sharer.php?u=" + this.state.currentVideo.video_url}
                        onClick={() =>
                          sendEvent({
                            ga:{
                              category:'Engagement',
                              action:'Share',
                              label: 'Facebook_Share_' + this.state.currentVideo.name
                            }
                          })
                        }
                        target="_blank"
                      ><img src={Fb}/></a>
                    </div>
                    <BlackOverlay/>
                  </VideoDetails> : null
                }

              </LargeVideo>
              <SmallVideos>
                {pets}
              </SmallVideos>
            </VideosContainer>
            <small style={{
              'text-align': 'center',
              width: '100%',
              margin: '0 auto',
              display: 'block',
              'font-family':
                'Montserrat'
            }}>All segments filmed before mandated social distancing protocols.</small>
          </MainWrapper>
        </HeroWrapper>
        <PadWrapper>
          <h2>Shelter Support</h2>
          <ShelterText>In 2019 we identified 4 major shelter partners in various regions across Canada to start the Home
            for Every Pet Project, contributing ROYALE<sup>®</sup> products and helping to fund in-shelter improvements.
            <br/><br/>
            Now we are officially launching the program to the public with the addition of a $50K cash donation to be
            shared by existing partners and new additions.
            <br/><br/>
            Our awareness-building activities and fund raising will continue as the program builds across the
            country.</ShelterText>
          <br/><br/>
          <h2>Our Shelter Partners</h2>
          <Spacer/>
          <SliderFlex ref={slider => (this.slider = slider)} {...settings}>
            <a href="https://www.torontohumanesociety.com/ " target="_blank"><img src={Partner1}/></a>
            <a href="https://www.monctonspca.ca/en " target="_blank"><img src={Partner2}/></a>
            <a href="https://spca.bc.ca/" target="_blank"><img src={Partner3}/></a>
            <a href="https://www.spca.com/en/" target="_blank"><img className="smaller-pic" src={Partner4}/></a>
            <a href="https://www.calgaryhumane.ca/" target="_blank"><img src={Partner5}/></a>
            <a href="https://www.edmontonhumanesociety.com/" target="_blank"><img src={Partner6}/></a>
            <a href="https://www.novascotiaspca.ca/" target="_blank"><img src={Partner7}/></a>
            <a href="https://spcastjohns.org/" target="_blank"><img src={Partner8}/></a>
            <a href="https://ottawahumane.ca/" target="_blank"><img src={Partner9}/></a>
            <a href="https://www.peihumanesociety.com/" target="_blank"><img src={Partner10}/></a>
            <a href="https://spadequebec.ca/" target="_blank"><img src={Partner11}/></a>
            <a href="https://reginahumanesociety.ca/" target="_blank"><img src={Partner12}/></a>
            <a href="http://www.spcaanimalrescue.com/" target="_blank"><img src={Partner13}/></a>
            <a href="https://www.winnipeghumanesociety.ca/" target="_blank"><img src={Partner14}/></a>
          </SliderFlex>
          <Flex flexWrap="wrap" mx={[0, 0, 0, -3]} style={{'margin-bottom': '30px'}}>
            <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 3]}>
              <Contribution src={Contrib1}/>
            </Box>
            <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 3]}>
              <Contribution src={Contrib2}/>
            </Box>
            <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 3]}>
              <Contribution src={Contrib3}/>
            </Box>
          </Flex>
          <small style={{
            'text-align': 'center',
            width: '100%',
            margin: '0 auto',
            display: 'block',
            'font-family':
              'Montserrat'
          }}>All segments photographed before mandated social distancing protocols.</small>
        </PadWrapper>
        <RenderBlock blocks={blocks}/>
        <DidYouKnow blogPosts={rhs} locale="en"/>

        <PetsSignUpBlock locale="en" />

        <h4 className="lasth4">
          Want to know more about the ROYALE<sup>®</sup> Home for Every Pet Project. Check out our FAQs section <a
          href="../faq#pets">HERE</a>.</h4>
          { browser.name == 'ie' ?
            '':<EventTracker gtag={{'allow_custom_scripts': true,'send_to': 'DC-8521957/invmedia/tiger0+standard'}} />
          }
        <Footer hideSignUp locale="en"/>
        <Offers />
      </Layout>
    )
  }
}

export const query = graphql`
query {
  startPage(guid: { eq: "54319571-6668-471c-bc49-71b26b4b0ddf" }, locale: { eq: "en" }) {
    title_html
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...promotionBlockFragment
    }
  }
  prismic {
    blog_1: home_solution_article(uid: "a-natural-way-to-keep-your-furry-friends-ears-clean", lang: "en-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
    blog_2: home_solution_article(uid: "stop-pet-mess-in-its-tracks", lang: "en-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
    blog_3: home_solution_article(uid: "an-irresistible-toy-for-cats", lang: "en-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
  }
}
`

export default PetsPage
